<template>
  <div>
    登出中..請稍候
  </div>
</template>

<script>
export default {
  name: 'Logout',
  components: {
  },
  data () {
    return {
      time: 1,
    }
  },
  mounted () {
    // set timeout 3 seconds and redirect to login page
    setTimeout(() => {
      this.$router.push('/login')
    }, 1500)
  },
}
</script>

<style scoped lang="scss">
.login {
  @apply w-full h-full;
}
</style>
